<template>
  <div>
    <h1>Bienvenido a su apartado para capturar sus centros de carga</h1>
    <b-form @reset="onReset" >
      <b-form-group id="input-group-1" label="ID:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="centroActual.identificador"
          placeholder="Ingrese el ID del centro de carga"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Ubicación:" label-for="input-2">
        <b-form-input
          v-model="centroActual.ubicacion"
          placeholder="Ingrese la ubicación del centro de carga">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Nombre proveedor:" label-for="input-3">
        <b-form-input
          v-model="centroActual.nombre"
          placeholder="Ingrese el nombre del proveedor">
        </b-form-input>
      </b-form-group>

   
    <b-button variant="dark" @click="enviarCentro" @dblclick="reloadPage">
      Enviar centro de carga
    </b-button>

    <b-button variant="dark" @click="reloadPage">
      Dar de alta otro centro de carga
    </b-button>

    <div>
    <br>
      <b-table striped hover :items="items"></b-table>
    </div>

    <br>
    <h6>Resumen centro</h6>
    {{centroActual}}
    <br>
    <h6>Resumen Inventario Centros</h6>
    {{inventario_centros}}

    <br>



    </b-form>

  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        inventario_centros: {
          centros:[
            {}
          ]
        },

        centroActual:{
              identificador:'',
              ubicacion:'',
              centro:'',
            },

        items: [
          { ID: '001', ubicacion: 'CARRETRA MEXICO TULANCIGO KM 32', proveedor: 'PEMEX MEXICO - TULANCINGO'},

        ],
        
      }
    },
    methods: {
    
      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta

        this.centroctual.identificador=''
        this.centroActual.ubicacion=''
        this.centroActual.centro=''
      },

      reloadPage() {
      window.location.reload();
    },

      enviarCentro(){
        this.inventario_centros.centros.push(JSON.parse(JSON.stringify(this.centroActual)));
          alert('Se guardaron correctamente los datos del empleado');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

    }
  }
</script>